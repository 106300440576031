const END_POINT = {
  // auth
  LOGIN: "/api/v1/public/auth/signin",
  LOGOUT: "/api/v1/private/auth/logout",

  // student
  STUDENT_INFO: "api/student/information",
  SUBJECT_CONTENT: "api/course/subject_contents",
  MAX_SCORE: "api/aspirational/score",
  GET_SCHEDULE_COURSE: "api/esmode/generate/json",
  EXPORT_SCHEDULE_COURSE: "api/gapcs/generate/export/excel",
  COMPULSORY_SUBJECTS: "api/aspirational/compulsory_subjects",
  COMPULSORY_SUBJECTS_V2: "api/aspirational/content_requirements_v2",
  ALL_COURSE_NAME: "api/course/course-information/all_with_status",
  GET_ALL_COURSE_FILTER: "api/course/course-information/all_course",
  SEARCH_COURSE_NAME: "api/course/course-names/search",
  GET_COURSE_BY_NAME: "api/course/course_information",
  ADD_NEW_CLASS_SCHEDULE: "api/ai-curriculum/ai-curriculum",
  GET_COURSE_TYPE_NAME: "api/course/type_name",
  GET_COURSE_SUBJECTS: "api/course/subjects",
  GET_COURSE_TYPE_COURSE: "/api/course/type_course",
  GET_COURSE_TEACHER_NAME: "/api/course/teacher-name",
  GET_COURSE_CONTENTS :"/api/course/contents",
  GET_INFO_MAINTENANCE: "/api/system/get-info-maintain",
  ADD_COURSE_FILTER:"/api/course/course-information/status_course",

  GET_INFOR_STUDENT_SAVED: "/api/ai-curriculum/detail",
  GET_INFORMATION_PRINT: "/api/student/information_excel",
  UPDATE_CALENDER: "/api/ai-curriculum/update_calendar",

  // Curriculum
  GET_LIST_CURRICULUM: "/api/ai-curriculum/list-detail",
  GET_CURRICULUM_DETAIL: "/api/ai-curriculum/multiple/detail",
  ADD_AI_CURRICULUM: "/api/ai-curriculum/multiple/ai-curriculum",
  ACTIVE_AI_CURRICULUM: "/api/ai-curriculum/ai-curriculum/activate",
  DELETE_AI_CURRICULUM: "/api/ai-curriculum/delete_ai_curriculums",

  // Aspirational
  GET_LIST_UNIVERSITY: "/api/aspirational/university",
  GET_ASPIRATIONAL_ID: "/api/aspirational/aspiration_id",
  GET_FULL_UNIVERSITY_INFORMATION:
    "/api/aspirational/full-university-information",

  // Admin
  LOGIN_ADMIN: "/api/login/login_admin",
  GET_LIST_COURSE_ADMIN: "/api/admin/list-course",
  IMPORT_LIST_COURSE_ADMIN: "/api/admin/list-course/import",
  IMPORT_LIST_COURSE_DEPENDENCIES_ADMIN:
    "/api/admin/course-dependencies/import",
  LIST_COURSE_DEPENDENCIES_ADMIN: "/api/admin/list-course-dependence",
  LIST_INVALID_COURSE_DEPENDENCIES_ADMIN: "/api/admin/list-course-dependence/invalid",
  LIST_12_GROUP_ADMIN: "/api/admin/list-course-school-link",
  LIST_INVALID_COURSE_ADMIN:"/api/admin/list-course/invalid",
  IMPORT_SEIHOKU_ADMIN: "/api/admin/seihoku/import",
  IMPORT_SCHOOL_GROUP_ADMIN: "/api/admin/university-group/import",
  GET_LIST_SCHOOL_GROUP_ADMIN: "/api/admin/university-group",
  GET_LIST_SEIHOKU_ADMIN: "/api/admin/seihoku/export",
  GET_LIST_VALID_SCHOOL: "/api/admin/university-group",
  GET_LIST_INVALID_SCHOOL: "/api/admin/university-group/invalid",
  GET_DETAIL_SEIHOKU_ADMIN: "/api/admin/seihoku/export/detail",
  GET_VERSION_SEIHOKU_ADMIN: "/api/admin/seihoku/version",
  GET_SERIES: "/api/admin/series",
  GET_SUBJECT: "/api/admin/subjects",
  GET_CLASSIFICATION: "/api/admin/classifications",
  GET_METHOD: "/api/admin/method",
  GET_CONTENT: "/api/admin/content",
  GET_EDITED: "/api/admin/edited",
  GET_EXAM: "/api/admin/exam",
  GET_VERSION: "/api/admin/version",
  GET_SCHOOL_GROUP: "/api/admin/school-group",
  GET_UNIVERSITY_ID: "/api/admin/seihoku/university_id",
  GET_VERSION_IMPORT: "/api/admin/version_import",
  GET_LIST_DATA_VERSION: "/api/admin/data-version",
  NEW_VERSION: "/api/admin/import-flow/new-version",
  CANCEL_IMPORT: "/api/admin/confirm_import",
  IMPORT_STEP_VERSION: "/api/admin/import-clone",
  DELETE_SCHEDULED: "/api/admin/remove_ai_curriculum",
  GET_LIST_SCHEDULES: "/api/admin/list-curriculum",
  GET_LIST_STUDENT_ID: "/api/admin/list-student-id",
  GET_LIST_STUDENT_NAME: "/api/admin/list-student-name",
  GET_LIST_UNIVERSITY_ADMIN: "/api/admin/university",
  GET_LIST_FULL_UNIVERSITY_INFOMATION: "/api/admin/full-university-information",
  GET_DETAIL_CURRICULUM: "/api/admin/detail-curriculum",
  GET_LINK_DOWNLOAD: "/api/admin/get-school-group-file",
  DELETE_MANAGER_SCHEDULED: "/api/admin/delete_ai_curriculums",
  GET_LIST_INVALID_SEIHOKU: "/api/admin/seihoku/invalid",
  MAINTENANCE_ADMIN: "/api/admin/maintain/update",
  GET_INFO_MAINTENANCE_ADMIN: "/api/admin/get-info-maintain"
};

export default END_POINT;
