export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const IS_EXPORT_STEP1 = !!+process.env.REACT_APP_IS_EXPORT_STEP1;

export const MESSAGE_STATUS = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
};

export const RESPONSE_STATUS = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  SUCCESS: "success",
};

export const CONTENT_SUBJECT_REQUIRED = ["総合", "対策"]

export const SUBJECT_REQUIRED = "社会"

export const SUBJECT_TITLE = {
  "英語": [],
  "国語": [],
  "数学": [],
  "社会": {},
  "理科": {}
}

export const DEFAULT_SCIENCE = [
  {
    "value": "理系",
    "label": "理系"
  },
  {
    "value": "文系",
    "label": "文系"
  }
]