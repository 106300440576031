export const errorForm = {
  REQUIRED: 'This field is required',
  IS_NUMBER: 'This field must be a `number` type',
  IS_STRING: 'This field must be a `string` type',
  IS_DATE: 'This field must be a `date time` type',
  IS_NUMBER_MIN: 'Must be greater than or equal to min',
  IS_NUMBER_MAX: 'Must be less than or equal to max',
  INTEGER: 'This field must be an integer',
  IS_DECIMAL: 'This field must be of type `decimal`, up to 6 decimal places',
  IS_DECIMAL_4: 'This field must be of type `decimal`, up to 4 decimal places',
  IS_DECIMAL_All: 'This field must be of type `decimal`',
  NUMBER_MIN_0: 'This field must be greater than or equal to 0',
  NUMBER_GREATER_0: 'This field must be greater than 0',
  NUMBER_GREATER_0_SHOT: 'Greater than 0',
  NUMBER_MIN_1: 'This field must be greater than or equal to 1',
  NUMBER_MAX_18: 'This field must be less than or equal to 18',
  NUMBER_MAX_100: 'This field must be less than or equal to 100',
  NUMBER_MAX_999999999999: 'This field must be less than or equal to 999,999,999,999',
  NUMBER_MAX_99999: 'This field must be less than or equal to 99,999',
  CHAR_MIN_1: 'This field must be at least 1 characters',
  CHAR_MAX_16: 'This field must be at most 16 characters',
  CHAR_MAX_20: 'This field must be at most 20 characters',
  CHAR_MAX_64: 'This field must be at most 64 characters',
  CHAR_MAX_76: 'This field must be at most 76 characters',
  CHAR_MAX_35: 'This field must be at most 35 characters',
  CHAR_MAX_59: 'This field must be at most 59 characters',
  CHAR_MAX_100: 'This field must be at most 100 characters',
  CHAR_MAX_255: 'This field must be at most 255 characters',
  CHAR_MAX_1000: 'This field must be at most 1000 characters',
  INVALID_EMAIL: 'Invalid email',
  INVALID_IMAGE: 'Please select images below 2MB in format',
  INVALID_BACKGROUND: 'Please select images below 3MB in format',
  INVALID_BANNER: 'Please select images below 10MB in format',
  INVALID_SIZE_FILE_NFT: 'Please select file below 40MB in format',
  INVALID_FILE_TYPE: 'Upload file must png, gif or jpeg',
  INVALID_FILE_UPLOAD_SIZE: 'Please select file below 3MB in format',
  INVALID_FILE_UPLOAD: 'Please upload in the correct format',
  INVALID_VIDEO_TYPE: 'Upload file must video',
  INVALID_FILE_TYPE_NFT_ITEMS: 'Upload file must jpg, png, gif, svg, mp4, mp3, wav',
  INVALID_FILE_TYPE_NFT_SECRET: 'Upload file must txt, pdf, jpg, png, gif, svg, mp4, mp3, wav',
  INVALID_URL: 'URL is invalid',
  NUMBER_MAX_TOKEN_SUPPLY: 'Token supply is not enough',
  INVALID_CSV_FILE: 'You can only upload csv file',
  SYSTEM_ERROR: 'System error',
  INVALID_TIME_NOW: 'Must be greater than now time',
  INVALID_START_TIME: 'Must be less than end time',
  INVALID_TIME_ROUND: 'Must be greater than end time of the previous round',
  INVALID_SALE_TIME: 'Must be greater than start time',
  INVALID_END_TIME: 'Must be less than listing date',
  INVALID_START_TIME_LISTING_TIME: 'Must be less than listing date',
  INVALID_TIME_TO_REPLACE_LISTING_TIME: 'Must be less than listing date',
  INVALID_TIME_TO_SWITCH_LISTING_TIME: 'Must be less than listing date',
  INVALID_LISTING_TIME: 'Must be greater than end time',
  INVALID_LISTING_TIME_START_TIME: 'Must be greater than start time',
  INVALID_LISTING_DISTRIBUTION: 'Must be less than distribution date',
  INVALID_DISTRIBUTION_LISTING_TIME: 'Must be greater than listing date',
  INVALID_DISTRIBUTION_END_TIME: 'Must be greater than end time',
  INVALID_END_TIME_DISTRIBUTION: 'Must be less than distribution date',
  INVALID_TIME_TO_REPLACE: 'Must be less than time to replace',
  INVALID_LESS_END_TIME: 'Must be less than end time',
  INVALID_TIME_TO_REPLACE_GREATER: 'Must be greater than time to replace',
  INVALID_TIME_TO_SWITCH: 'Must be less than time to switch',
  INVALID_TIME_TO_SWITCH_GREATER: 'Must be greater than time to switch',
  INVALID_VALUE: 'Invalid value',
  SOFT_CAP_ERROR: 'Soft cap must be less than hard cap',
  SOFT_CAP_LESS_HARD_CAP: 'Soft cap must be less than hard cap',
  HARD_CAP_GREATER_SOFT_CAP: 'Hard cap must be greater than soft cap',
  DECIMAL_INVALID: 'Decimal is invalid',
  MAX_SALE_ALLOCATION_INVALID: 'Amount must be less than or equal to balance',
  SALE_ALLOCATION_INVALID: "Sale allocation must be greater than 0",
  MAX_ALLOCATION_INVALID: 'Max allocation must be less than hard cap',
  HARD_CAP_GREATER_MAX_ALLOCATION: 'Hard cap must be greater than max allocation',
  MAX_ALLOCATION_INVALID_AFTER_SWITCH: 'Max allocation after switch must be less than hard cap',
  HARD_CAP_GREATER_MAX_ALLOCATION_AFTER_SWITCH: 'Hard cap must be greater than max allocation after switch',
  MAX_ALLOCATION_INVALID_IN_CSV_WITH_HARD_CAP: 'Max allocation in file csv must be less than hard cap',
  HARD_CAP_GREATER_MAX_ALLOCATION_IN_CSV_REPLACE: 'Hard cap must be greater than max allocation in file csv whitelist replace',
  HARD_CAP_GREATER_MAX_ALLOCATION_IN_CSV: 'Hard cap must be greater than max allocation in file csv whitelist',
  MAX_ALLOCATION_INVALID_IN_CSV_WITH_MIN_ALLOCATION: 'Max allocation in file csv must be greater than min allocation',
  MIN_ALLOCATION_LESS_MAX_ALLOCATION_INVALID_IN_CSV: 'Min allocation must be less than max allocation in file csv whitelist',
  MIN_ALLOCATION_LESS_MAX_ALLOCATION_INVALID_IN_CSV_REPLACE: 'Min allocation must be less than max allocation in file csv whitelist replace',
  MIN_ALLOCATION_INVALID: 'Min allocation must be less than max allocation',
  MAX_ALLOCATION_GREATER_MIN_ALLOCATION: 'Max allocation must be greater than min allocation',
  MAX_ALLOCATION_AFTER_SWITCH_GREATER_MIN_ALLOCATION: 'Max allocation after switch must be greater than min allocation',
  MIN_ALLOCATION_INVALID_AFTER_SWITCH: 'Min allocation must be less than max allocation after switch',
  MIN_ALLOCATION_INVALID_HARD_CAP: 'Min allocation must be less than hard cap',
  MAX_ALLOCATION_INVALID_HARD_CAP: 'Max allocation must be less than hard cap',
  MAX_ALLOCATION_AFTER_SWITCH_INVALID_HARD_CAP: 'Max allocation after switch must be less than hard cap',
  HARD_CAP_GREATER_MIN_ALLOCATION: 'Hard cap must be greater than min allocation',
  MIN_ALLOCATION_INVALID_AFTER_SWITCH_IN_CSV_WITH_MAX_ALLOCATION: 'Min allocation must be less than max allocation in file csv',
  GREATER_THAN_0: 'This field must be greater than 0',
  GREATER_THAN_5K_QUARTER: 'Rolling Funds subscriptions start at $5k per quarter',
  START_TIME_AFTER: 'Must be after current time',
  CONTRACT_ADDRESS_IS_LIVE_OR_UPCOMING: 'Token is live or upcoming or has been sold successfully',
  INVALID_TOKEN_ADDRESS: 'Invalid token address',
  INVALID_WALLET_ADDRESS: 'Invalid wallet address',
  NOT_ENOUGH: 'Not enough',
  AMOUNT_LESS_THAN_HARD_CAP: 'Amount must be less than hard cap',
  TOKEN_HAS_BEEN_SOLD_OUT: 'All tokens has been sold out',
  NOT_ENOUGH_BALANCE: 'Not enough balance in wallet',
  INVALID_WHITELIST: 'Invalid whitelist',
  DUPLICATE_ADDRESS_WHITELIST: 'Whitelist is duplicated',
  TIME_HAS_PASSED: 'Time has passed',
  CAN_NOT_GET_DEPOSIT: 'Can not get deposit',
  CAN_NOT_GET_GAS_PRICE: 'Can not get gas price',
  SELECT_FAILURE_TIME: 'Select failure time, please select this field again',
  SELECT_FAILURE_TIME_START: 'Select failure time, please select this field again (Start Time)',
  SELECT_FAILURE_TIME_END: 'Select failure time, please select this field again (End Time)',
  SELECT_FAILURE_TIME_LISTING: 'Select failure time, please select this field again (Listing Date)',
  MAX_WALLET_BALANCE: 'This field must be less than or equal to Wallet Balance',
  INVALID_IMAGE_WIdTH_HEIGHT: 'Please choose a larger width and height than required by the image',
  WALLET_DIFFERENCE_CURRENT_WALLET: 'Wallet address must be different from current wallet address',
  INVALID_VESTING_TIMEZONE: 'Invalid vesting period, "Timezone" must be "UTC"',
  INVALID_VESTING_DATE: 'Invalid vesting period, "Distribution time" must be "MM/DD/YYYY HH:mm"',
  INVALID_VESTING_DATE_DUPLICATE: 'Invalid vesting period, "Distribution time" is duplicated',
  INVALID_VESTING_EMPTY: 'Vesting period must be no empty value',
  INVALID_VESTING_UNLOCKRATE: '"Unlock rate" must be "number%" (number with maximum 4 decimals)',
  INVALID_VESTING_UNLOCKRATE_SUM: 'Unlock rate sum must be 100%',
  INVALID_VESTING_DATE_TIME_NOW: 'Distribution time must be greater than now time',
  INVALID_VESTING_DATE_END_TIME: 'Distribution time must be greater than end time',
  INVALID_VESTING_DATE_START_TIME: 'Distribution time must be greater than start time',
  INVALID_VESTING_DATE_LISTING_TIME: 'Distribution time must be greater than listing time',
  INVALID_VESTING_DATE_TIME_TO_REPLACE: 'Distribution time must be greater than time to replace',
  INVALID_VESTING_DATE_TIME_TO_SWITCH: 'Distribution time must be greater than time to switch',
  INVALID_VESTING_DATE_ASC: 'Distribution time must be sorted by ascending and must not be duplicated',
  INVALID_LESS_TIME_VESTING: 'Must be less time in vesting period',
  INVALID_LAST_LISTING_TIME_LESS_TIME_VESTING: 'Listing time last update must be less time in the vesting period',
  INVALID_FILE_CSV: 'The csv file is empty or invalid format',
  REQUIRED_TOKEN_NAME: "Required token name",
  TOKEN_CONTRACT_ADDRESS_EXISTED: "Token contract address already exists",
  INVESTMENT_PER_QUARTER_MAX: 'Investment per Quarter over limited',
  MANAGERMENT_FEE_LIMITED: 'Most fund managers charge between 1%-3% in management fees',
  CARRY_LIMITED: 'Most fund managers charge 10%-30% in carry',
  COURSES_IS_EMPTY: 'List courses additions is empty.',
  ITEM_IS_IN_SCHEDULE: 'Course is in schedule.',
  EXPORT_EXCEL_ERROR: 'Export excel error',
}

export const successForm = {
  EXPORT_EXCEL_SUCCESS: 'Export excel success',
  ADD_COURSE_SUCCESS: 'Add course success',
}

export const ERROR_API_MESSAGE = {
  1: 'システムエラーが発生しました。',
  2: '不正な文字が入力されています。',
  3: 'データが見つかりません。',
  4: '生徒IDの学校は適切ではありません。',
  5: "当該生徒は対象学年ではありません。",
  6: "生徒の志望大学に関する情報は適切ではありません。",
  7: "当該の日程・方式がありません。",
  8: "コミュニケーションウェアに生徒の志望大学がありませんでした。",
  9: "学科・専攻に対しての志望大学コードが見つかりません。",
  10: "生徒IDが見つかりません。",
  11: "時限数/週もしくは受講開始期間が正しくありません。",
  12: "受講予定講座がありません。",
  16: "当該生徒IDの適性検査情報が正しくありません。",
  18: "選択した内容に応じる講座がありません。",
  ERR_NETWORK: "ネットワークエラーが発生しました。",
  104: "ユーザーIDまたはパスワードが正しくありません。",
  100: "トークンが不正です。",
  101: "エラーが発生しました。",
  102: "トークンの有効期限が切れています。",
  14: "Excelファイルの書式が正しくありません。再度ファイルを選択してください。",
  15: "Excelファイルが空白になっています。",
  17: "エラーが発生しました。",
  20: "すでにインポート中のバージョンが存在しています。",
  19: "インポートの順番が間違っています。",
  21: "こちらのバージョンが削除されたため、他の操作ができません。",
  22: "こちらのバージョンが認証されたため、他の操作ができません。",
  23: "こちらのバージョンがインポート中のため、他の操作ができません。",
  25: "こちらのバージョンが完了したため、他の操作ができません。",
  26: "選択したファイル内に不正値が含まれています。",
  28: "ファイルが見つかりません。",
  24: "データが見つかりません。",
  29: "受講予定講座数は30件まで生成できます。",
  30: "申し訳ございません。ただいまメンテナンス中です。",
  31: "この操作を実施する権限がありません。"
}